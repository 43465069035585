$(document).ready(function() {

    $('.carousel-top').owlCarousel({        
        loop:true,
        autoWidth:true,
        items:1,
        dots: false,
        nav: false       
    })
    
    $('.carousel-regions').owlCarousel({        
        loop:true,        
        items:2,
        center: true,
    })
    
    $('[data-toggle="popover"]')        
        .popover({           
            trigger: 'click',
            container: 'body',
            html: true,
            template: '<div class="popover" role="tooltip"><div class="arrow"></div><div class="popover-body"></div></div>',
            content: function() {
                var targetId = $(this).attr('data-target');                
                return ($('#'+ targetId).html());
            }
        });        
});
